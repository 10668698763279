import { useQuery } from '@apollo/client';

import type {
  GetMatchedQualitiesQuery,
  GetMatchedQualitiesQueryVariables,
} from 'src/graphql/graphql.ts';
import { GET_JOB_DETAILS_MATCHED_QUALITIES } from 'src/modules/graphql/queries';
import { useSelector } from 'src/store/react';
import { selectFeatureFlag } from 'src/store/selectors';
import { selectAuthenticated } from 'src/store/user/selectors.ts';

export const useSkillsForJDV = (jobDetailsId: string) => {
  const isAuthenticated = useSelector(selectAuthenticated);
  const enableMatchedQualities = useSelector(
    selectFeatureFlag('matchedQualities'),
  );

  const { data, loading, error } = useQuery<
    GetMatchedQualitiesQuery,
    GetMatchedQualitiesQueryVariables
  >(GET_JOB_DETAILS_MATCHED_QUALITIES, {
    variables: {
      jobDetailsId,
      // todo add tracking
    },
    skip: !isAuthenticated || !enableMatchedQualities,
  });

  return {
    qualities: data?.jobDetails?.personalised?.qualities,
    error,
    loading,
  };
};
