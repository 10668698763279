import { useEffect } from 'react';

import { useMetricsTimerContext } from 'src/modules/MetricsTimer/MetricsTimerContext';

export const useStopTimerOnComponentVisible = ({
  ref,
  actionName,
  additionalTags,
}: {
  actionName: string;
  additionalTags?: string[];
  ref: React.MutableRefObject<HTMLElement | null>;
}) => {
  const metrics = useMetricsTimerContext();
  useEffect(() => {
    if (!ref.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          metrics.stopTimer(actionName, additionalTags ?? []);
          observer.disconnect();
        }
      },
      { threshold: 0.1 },
    );

    observer.observe(ref.current);

    return () => observer.disconnect();

    // Context does not need to be in the dependency array, actionName should not change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);
};
