import gql from 'graphql-tag';

export const UPDATE_SKILLS = gql`
  mutation UpdateSkills($input: UpdateSkillsInput!) {
    updateSkills(input: $input) {
      viewer {
        skills2 {
          keyword {
            text
            ontologyId
          }
        }
      }
    }
  }
`;
