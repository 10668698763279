import { useLazyQuery, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import type {
  GetSkillsQuery,
  GetSkillsQueryVariables,
  Skill,
  SkillInput,
  UpdateSkillsInput,
  UpdateSkillsMutationVariables,
} from 'src/graphql/graphql';
import { UPDATE_SKILLS } from 'src/modules/graphql/mutations/skills';
import { GET_SKILLS } from 'src/modules/graphql/queries/skills';
import { logger } from 'src/modules/logger';

type UpdateSkillError = 'UpdateSkillError' | 'SkillLimitReached';

export type UpdateSkillResponse =
  | UpdateSkillError
  | 'UpdateSkillSuccess'
  | undefined;

export const useSkills = (limit: number = 50) => {
  const [getSkills] = useLazyQuery<GetSkillsQuery, GetSkillsQueryVariables>(
    GET_SKILLS,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [updateSkillsMutation] = useMutation<
    UpdateSkillsInput,
    UpdateSkillsMutationVariables
  >(UPDATE_SKILLS);

  const updateSkills = useCallback(
    async (newSkills: SkillInput[]) => {
      const result = await updateSkillsMutation({
        variables: { input: { skills: newSkills } },
      });

      if (result.errors) {
        logger.error(result.errors, 'Could not update candidate skills');
        return 'UpdateSkillError';
      }
      return 'UpdateSkillSuccess';
    },
    [updateSkillsMutation],
  );

  const ensureSkillInput = ({
    keyword: { text, ontologyId },
  }: Skill): SkillInput => ({ keyword: { text, ontologyId } });

  const addSkill = useCallback(
    async (skill: SkillInput): Promise<UpdateSkillResponse> => {
      const skillsQueryResult = await getSkills();
      const skills = skillsQueryResult?.data?.viewer?.skills2 || [];

      if (skills.length >= limit) {
        return 'SkillLimitReached';
      }

      if (
        !skills.find(
          ({ keyword: { text, ontologyId } }) =>
            skill.keyword.ontologyId === ontologyId &&
            skill.keyword.text === text,
        )
      ) {
        const newSkills = skills.filter(
          (s) => s.keyword.text !== skill.keyword.text,
        );
        newSkills.push(skill);

        return updateSkills(newSkills.map((s) => ensureSkillInput(s)));
      }

      return 'UpdateSkillSuccess';
    },
    [getSkills, limit, updateSkills],
  );

  const removeSkill = useCallback(
    async (skill: Skill): Promise<UpdateSkillResponse> => {
      const skillsQueryResult = await getSkills();
      const skills = skillsQueryResult?.data?.viewer?.skills2 || [];

      if (
        !skills.find(
          ({ keyword: { text, ontologyId } }) =>
            skill.keyword.ontologyId === ontologyId &&
            skill.keyword.text === text,
        )
      ) {
        return 'UpdateSkillSuccess';
      }
      const newSkills = skills.filter(
        (s) => s.keyword.text !== skill.keyword.text,
      );

      return await updateSkills(newSkills.map((s) => ensureSkillInput(s)));
    },
    [getSkills, updateSkills],
  );

  return { addSkill, removeSkill };
};
