import type { SearchResultJob, SearchResultJobV5 } from '@seek/chalice-types';
import { hrefFromLocation } from '@seek/seek-jobs-seo';
import { useTranslations } from '@vocab/react';
import { Inline, Text, TextLink } from 'braid-design-system';
import { useMemo } from 'react';

import { getRefineSearchQuery } from 'src/components/Search/utils/utils';
import { useAppConfig } from 'src/config/appConfig';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { isJobExternal } from 'src/modules/qualified-location';
import { useSelector } from 'src/store/react';
import {
  selectFeatureFlag,
  selectResults,
  selectSearchQuery,
} from 'src/store/selectors';

import translations from '../.vocab';
import useSeoFooter from '../useSeoFooter';

interface Suburb {
  label: string;
  isExternal: boolean;
}
const MINIMUM_TO_DISPLAY = 2;

const useUnifiedLocationSuburbs = () => {
  const jobs = useSelector(selectResults)?.jobs;
  const { country } = useAppConfig();
  const isV5Search = useSelector(selectFeatureFlag('v5JobSearch'));

  return useMemo(() => {
    if (!jobs) {
      return [];
    }

    const suburbs = new Map<string, Suburb>();
    if (isV5Search) {
      jobs.forEach((currentJob) => {
        const job = currentJob as SearchResultJobV5;
        // V5 supports an array of locations but we only need to worry about the first one for now
        const location = job.locations[0];
        const label = location?.seoHierarchy[0]?.contextualName ?? '';
        if (label) {
          suburbs.set(label, {
            label,
            isExternal: isJobExternal(country, location?.countryCode),
          });
        }
      });
    } else {
      jobs.forEach((currentJob) => {
        const job = currentJob as SearchResultJob;
        const label = job.jobLocation?.seoHierarchy[0]?.contextualName ?? '';
        if (label) {
          suburbs.set(label, {
            label,
            isExternal: isJobExternal(country, job.jobLocation?.countryCode),
          });
        }
      });
    }

    return Array.from(suburbs.values());
  }, [country, jobs, isV5Search]);
};

const SUBURBS_DISPLAY_LIMIT_SPLIT_VIEW = 6;
export const useSuburbsDisplayCount = () => SUBURBS_DISPLAY_LIMIT_SPLIT_VIEW;
export const useShouldRenderSuburbs = () => {
  const suburbs = useUnifiedLocationSuburbs();
  const suburbsToDisplay = useSuburbsDisplayCount();
  return {
    shouldShow: suburbs.length > MINIMUM_TO_DISPLAY,
    isSuburbsOverflow: suburbs.length > suburbsToDisplay,
  };
};

const RefineByLocation = ({
  shouldDisplayAll,
}: {
  shouldDisplayAll: boolean;
}) => {
  const { t } = useTranslations(translations);
  const suburbs = useUnifiedLocationSuburbs();
  const searchQuery = getRefineSearchQuery(useSelector(selectSearchQuery));
  const analyticsFacade = useAnalyticsFacade();
  const suburbsToDisplay = useSuburbsDisplayCount();
  const suburbsToRender = useMemo(
    () => (shouldDisplayAll ? suburbs : suburbs.slice(0, suburbsToDisplay)),
    [shouldDisplayAll, suburbs, suburbsToDisplay],
  );
  const { tabIndex } = useSeoFooter();

  return (
    <Inline component="ul" space="small">
      {suburbsToRender.map(({ label, isExternal }) => {
        const { page: _page, ...searchQueryWithoutPage } = searchQuery;
        const href = hrefFromLocation({
          pathname: '/jobs',
          query: {
            ...searchQueryWithoutPage,
            where: label,
          },
        });
        return (
          <Text size="small" key={label}>
            <TextLink
              href={href}
              data-automation="suburb-link"
              tabIndex={tabIndex}
              weight="weak"
              aria-label={t('Jobs in {suburb}', {
                suburb: label,
              })}
              onClick={() => analyticsFacade.searchBySuburbClicked()}
              rel={isExternal ? 'nofollow' : ''}
            >
              {label}
            </TextLink>
          </Text>
        );
      })}
    </Inline>
  );
};

export default RefineByLocation;
