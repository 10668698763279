import type { Skill } from '@seek/ca-graphql-schema/types';
import type { JobDetailsView } from '@seek/job-details-view';
import type React from 'react';

import { useSkills } from 'src/hooks/useSkills/useSkills';
import { useSkillsForJDV } from 'src/hooks/useSkills/useSkillsForJDV';
import { logger } from 'src/modules/logger';
import { useSelector } from 'src/store/react';
import { selectHasResume, selectSkillCount } from 'src/store/user/selectors';

const SKILL_LIMIT = 50; // todo confirm skill limit value

export const defaultPropsForJDVSkillsMatches = {
  hasReachedSkillLimit: false,
  hasResume: true,
  error: undefined,
  isLoadingQualities: false,
  onAddQuality: () => {},
  onRemoveQuality: () => {},
};

export const useJDVSkillsMatches = (
  jobId: string,
): React.ComponentProps<typeof JobDetailsView>['qualities'] => {
  const { addSkill, removeSkill } = useSkills(SKILL_LIMIT);
  const { qualities, error, loading } = useSkillsForJDV(jobId);
  const { matched, inferred, unmatched } = qualities ?? {};

  const skillCount = useSelector(selectSkillCount);
  const hasResume = useSelector(selectHasResume);
  if (error) {
    logger.error(error, 'useJDVSkillsMatches.ts');
  }
  return {
    matched: matched as Skill[],
    inferred: inferred as Skill[],
    unmatched: unmatched as Skill[],
    hasReachedSkillLimit: skillCount === SKILL_LIMIT,
    hasResume,
    isLoadingQualities: loading,
    error: error ? 'NetworkError' : undefined,
    onAddQuality: addSkill,
    onRemoveQuality: removeSkill,
  };
};
