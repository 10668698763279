import gql from 'graphql-tag';

export const GET_SKILLS = gql`
  query GetSkills {
    viewer {
      _id
      skills2 {
        keyword {
          text
          ontologyId
        }
      }
    }
  }
`;
